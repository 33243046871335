@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.header {
    position: relative;
    top: 0;
    width: 100%;
    text-align: center;
    padding-top: 20px;
    background-color: transparent;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-around;
    transition: background-color 0.3s ease;
    z-index: 1000;
    
}

.header.scrolled {
    position: fixed;
    backdrop-filter: blur(2rem);
    color: green; /* Change text color when scrolled */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4); /* Optional: Add shadow for better visibility */
}

.header-logo > h1 {
    /* Black italic */
    font-size: 2em;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-style: italic;
    color: white;
    cursor: pointer;
}

.header-logo > p {
    font-size: 1em;
    font-family: 'Roboto', sans-serif;
    font-style: italic;
    color: white;
}

.header-logo > .author {
    font-size: 1em;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-style: italic;
    color: white;
}

.header p {
    margin: 5px 0;
}

.header-navigation, ul, li {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0 0 0;
}

.header-navigation nav ul li {
    margin: 0 15px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-size: 1em;
    padding: 10px;
    transition: color 0.3s ease;
}


.header-navigation nav ul li {
    position: relative;
}

.infinity-image {
    width: 300px;
    height: 50px;
}

.header-navigation nav ul li::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px; /* Adjust thickness of underline */
    bottom: 0;
    left: 0;
    background-color: currentColor; /* Use the current text color */
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 300ms ease-out;
}

.header-navigation nav ul li:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

/* Change link color when header is scrolled */
.header.scrolled .header-navigation nav ul li {
    color: white;
}



/* Media Queries */
@media (max-width: 768px) {
    .header {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 2000;
    }

    .header-navigation nav ul {
        align-items: center;
        font-weight: bold;  
    }

}

@media (max-width: 500px) {
    .header-logo > h1 {
        font-size: 1.2em;
    }

    .header-logo > p, .header-logo > .author {
        font-size: 0.9em;
    }

    .header-navigation nav ul li {
        font-size: 0.8em;
        padding: 5px;
        margin: 5px 0;
    }
}
