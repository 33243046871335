@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.map-container {
  width: 100%;
  height: 100vh;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  margin-top: 50px;
}
.map-header {
  display: flex;
  margin-bottom: 50px;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto', sans-serif;
 
}
.map-header > p {
  font-weight: 200;
}

.map-header > h2 {
  font-weight: 500;
  font-size: 30px;
}

.map-container > h2 {
  margin-top: -20px;
  font-weight: 300;
}

.map {
  width: 80%;
  height: 80%;
}

/* Adjust the size for tablets */
@media (max-width: 768px) {
  .map-container {
    height: 70vh; /* Adjust as needed */
  }
}

/* Adjust the size for mobile devices */
@media (max-width: 480px) {
  .map-container {
    height: 60vh; /* Adjust as needed */
  }
}