
  
.contact-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.contact-form p {
  text-align: center;
  margin-bottom: 40px;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 20px;
 
}

.contact-form form label {
  margin-bottom: 10px;
  width: 100%;
  max-width: 500px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 1.2em;
  
}

.contact-form form input,
.contact-form form textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: none;
  font-size: 1em;
  border-bottom: 2px solid #2b2a2a; /* Add bottom border */
  background: none; /* Remove background */
  border-radius: 0; /* Remove border-radius */
  outline: none; /* Remove outline */
  color: white; /* Set text color */
}

.contact-form form input:focus,
.contact-form form textarea:focus {
  border-bottom-color: white; /* Change border color on focus */
}

.contact-form form button {
  padding: 10px 100px;
  margin-top: 20px;
  border: none;
  font-family: 'Roboto', sans-serif;
  border-radius: 5px;
  background: transparent;
  color: white;
  cursor: pointer;
  border: 1px solid white;
  transition: 150ms;
}

.contact-form form button:hover {
  border-radius: 20px;
  transition: 150ms;
}
.contact-card {
 
  width: 600px;
  color: white;
  border-radius: 30px;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2rem);
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 1rem #00000033;
}

.contact-form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-logo > p {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}
