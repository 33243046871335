.app {
  font-family: "Arial", sans-serif;
  position: relative; /* Ensure the pseudo-element is positioned relative to .app */
  color: white;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../src/images/TrueForest.png") no-repeat center center;
  background-size: cover;
  overflow: hidden;

}

* {
  margin: 0;
  padding: 0;
}
