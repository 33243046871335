.services-logo {
  text-align: center;
  font-family: "Roboto", sans-serif;
  margin-bottom: 30px;
}

.services-logo > h2 {
  font-size: 1.5rem;
  font-weight: 300;
  font-weight: bold;
}

.services-container {
  display: flex;
  font-family: "Roboto", sans-serif;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  margin-top: 20px;
  margin-bottom: 150px;
}

.provided-services {
  width: 90vw; /* Adjusted width for better responsiveness */
  max-width: 1200px; /* Added max-width for better scaling */
  display: flex;
  flex-wrap: wrap;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.card {
  box-sizing: border-box;
  width: 500px;
  height: 500px;
  text-align: center;
  /* height: 350px; Adjusted height for better scaling */
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1rem);
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 1rem #00000033;
  padding: 2rem 1rem;
  /* text-wrap: nowrap; */
}

.card .title {
  text-transform: uppercase;
  letter-spacing: 2px;

  width: 100%;
}

.card .content {
  flex: 1;
  display: flex;
  width: 100%;
  /* background-color: red; */
  padding: 1rem;
}

.card .content ol {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 4rem;
  align-items: center;
  justify-content: center;
}

.card > ul {
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
}

.card > h3 {
  font-size: 1.5rem;
  font-weight: bold;
  width: 100%;
  text-align: center;
}

ul,
li {
  font-size: 1rem;
  text-align: left;
}

ul.service-list-special {
  list-style-type: circle;
}
.service-list-special {
  align-items: flex-start;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  line-height: 14px;
}

.service-list-special ul {
  list-style-type: circle;
}
.service-list-special li {
  font-weight: 500;
}

@media (max-width: 450px) {
  .special-card {
    height: 220px; /* Adjust as needed */
  }
  .special-card2 {
    height: 400px;
  }
  .special-card3 {
    height: 600px;
  }
}