/* Footer styles */
.footer {
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1rem);
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* Change this value */

  align-items: center;
  min-height: 30vh;
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  font-family: "Roboto", sans-serif;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.243);
}
.footer {
  gap: 100px;
}

@media (max-width: 1200px) {
  .footer {
    gap: 50px;
  }
}

@media (max-width: 750px) {
  .footer {
    gap: 30px;
  }
  .footer-card > h2 {
    font-size: 1rem;
  }
  .footer-card > p {
    font-size: 1rem;
  }
}

@media (max-width: 660px) {
  .footer {
    gap: 5px;
  }
  .footer-card > h2 {
    font-size: 0.7rem;
  }
  .footer-card > p {
    font-size: 0.7rem;
  }
}

.footer-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
